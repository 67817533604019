// frontend title
export const dashboard_title_text = {
    vins_title: "VINS Pagespeed Documentor",
    vins_pagespeed_info_title: "Optimize Site Speed to Work Efficiently on All Screens",
    vins_audit_points: "Analysis What's your website's page speed and What are all things need to improve.",
    vins_page_title: "Page Title",
    vins_page_url: "Page URL"
}
export const page_dropdown = {
    home: "Home page",
    collection1: "Collection page 1",
    collection2: "Collection page 2",
    pdp1: "Product details page 1",
    pdp2: "Product details page 2",
    slp1: "Search result page 1",
    slp2: "Search result page 2",
    other: "Others"
}
export const Page_score_color = {
    numericLCP_Green: 2.5,
    numericLCP_Orange: 4,
    numericFCP_Green: 1.8,
    numericFCP_Orange: 3,
    numericCLS_Green: 0.1,
    numericCLS_Orange: 0.25
}
export const buttonText = {
    Btn_add: "Add Pages",
    Btn_remove: "Remove",
    Btn_audit: "Audit",
    Btn_auditAll: "Audit All",
    Btn_removeAll: "Remove All"
}
export const Excel_header = {
    empty_space: "",
    s_no: "S.No",
    page_name: "Page Name",
    page_url: "Page URL",
    desktop: "Desktop",
    desktop_score: "Score",
    desktop_fcp: "FCP",
    desktop_lcp: "LCP",
    desktop_cls: "CLS",
    mobile: "Mobile",
    mobile_score: "Score",
    mobile_fcp: "FCP",
    mobile_lcp: "LCP",
    mobile_cls: "CLS"
}
export const strategy = {
    mobile: "mobile",
    desktop: "desktop"
}
export const PageSpeedImprovementHeader = {
    s_no: "S.No",
    pageName: "Page_name",
    module: "module",
    description: "description",
    remarks: "remarks"
}
export const PageSpeedDescriptionExcelHeader = {
    s_no: "S.No",
    pageName: "Page Name",
    module: "Modules",
    imrpovement: "Improvement item",
    description: "Description",
    remarks: "Remarks",
    empty: ""
}
export const PageSpeedImprovemnetNotNeed = {
    content: "Page performance is good, we don't need page speed improvement."
}
export const PageSpeedDescriptionEtaHeader = {
    s_no: "S.No",
    taskName: "Task Name",
    databaseAdministration: "Database Administration",
    projectManagement: "Project Management",
    visualDesign: "Visual Design",
    qa: "QA",
    api: "API",
    research: "Research / Troubleshooting",
    programming: "Programming",
    systemAdministration: "System Administration",
    issueType: "UI/Functionality",
    issue_improvement: "Issue/Improvements",
    totalEfforts: "Total Efforts",
    remarks: "Remarks",
    empty: "",
}
export const validationErrorMessage = {
    pageNameEmpty: "Page Name is required",
    pageUrlEmpty: "Page URL is required",
    validPageUrl: "Page URL must be a valid URL"
}
export const BreakdownTitle = {
    title: "Breakdown Hours"
}
export const FormValues = {
    PageNamePlaceholder: "Select a page",
    CustomPageNamePlaceholder: "Enter custom page name",
    PageUrlDesktopPlaceholder: "https://example.com",
    PageUrlMobilePlaceholder: "Page URL"
}
//Data to check that has been available
export const titleInputs = ["Preload largest contentful paint image", "Reduce the impact of third-party code", "Properly size images", "Eliminate render-blocking resources", "Defer off-screen image", "Largest contentful paint image was lazily loaded", "Minify JavaScript", "Reduce JavaScript execution time", "Minify CSS", "Largest contentful paint element", "Avoid large layout shifts", "Reduce unused JavaScript", "Reduce unused CSS", "Minimise main-thread work", "Serve images in next-gen formats"];

export const title_with_url = ["Reduce unused JavaScript", "Reduce unused CSS", "Minimize main-thread work", "Serve images in next-gen formats", "Defer off-screen image", "Reduce JavaScript execution time"]
// Data for improvements,modules,and remarks item
export const staticRecordsofImprovement = [
    {
        "s__No": 2,
        "page_name": "Page speed items",
        "modules": "JS",
        "improvement_item": "Remove unwanted console log, settimeout if used.",
        "task": "Remove unwanted console log, settimeout if used.",
        "empty": "",
        "description": "We need to remove the console logs and unwanted settimeout.",
        "remarks": ""
    },
    {
        "s__No": 2,
        "page_name": "Page speed items",
        "modules": "",
        "improvement_item": "Compress images if higher image size is used.",
        "task": "Compress images if higher image size is used.",
        "empty": "",
        "description": "We need to compress the images if uploaded in larger sizes.",
        "remarks": ""
    }
];
export const titleMapping = {
    "static data 1": {
        "page_name": "Page speed items",
        "Modules": "",
        "task": "Compress images if higher image size is used.",
        "improvement_item": "Compress images if higher image size is used.",
        "description": "We need to compress the images if uploaded in larger sizes.",
        "database": '',
        "project": '',
        "design": 1,
        "qa": '',
        "api": '',
        "research": '',
        "programming": '',
        "system": '',
        "total_efforts": '',
        "remarks": 'FYI: The hours provided is only for 20 images, hours will differ based on the image count.'
    },
    "Static data 2": {
        "page_name": "Page speed items",
        "Modules": "JS",
        "task": "Remove unwanted console log, settimeout if used.",
        "improvement_item": "Remove unwanted console log, settimeout if used.",
        "description": "We need to remove the console logs and unwanted settimeout.",
        "database": '',
        "project": '',
        "design": '',
        "qa": '',
        "api": '',
        "research": '',
        "programming": 1,
        "system": '',
        "total_efforts": '',
        "remarks": 'FYI: The hours provided is only for 20 files , hours will differ based on the file count.'
    },
    "Preload largest contentful paint image": {
        "page_name": "Page speed items",
        "Modules": "HTML",
        "task": "Preload Important Resources / Establish Third-party Connections Early",
        "improvement_item": "Preload Important Resources / Establish Third-party Connections Early",
        "description": "We need to add rel='preload' for the large sized images",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": '',
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Reduce the impact of third-party code": {
        "page_name": "Page speed items",
        "Modules": "HTML",
        "task": "Defer JavaScript / Efficient loading third party scripts",
        "improvement_item": "Defer JavaScript / Efficient loading third party scripts",
        "description": "We need to add `async` or `defer` attributes in our `<script>` tags to ensure third-party scripts don’t block the rendering of our page.",
        "database": '',
        "project": '',
        "design": '',
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Properly size images": {
        "page_name": "Page speed items",
        "Modules": "",
        "task": "Use Responsive images(Srcset) if not used",
        "improvement_item": "Use Responsive images(Srcset) if not used",
        "description": "We need to add `srcset` to render the images properly across different screen sizes and resolutions.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Eliminate render-blocking resources": {
        "page_name": "Page speed items",
        "Modules": "HTML",
        "task": "Defer JavaScript / Efficient loading third party scripts",
        "improvement_item": "Defer JavaScript / Efficient loading third party scripts",
        "description": "We need to add `async` or `defer` attributes in our `<script>` tags to ensure third-party scripts don’t block the rendering of our page.",
        "database": '',
        "project": '',
        "design": '',
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Defer off-screen image": {
        "page_name": "Page speed items",
        "Modules": "",
        "task": "Lazy load images",
        "improvement_item": "Lazy load images",
        "description": "We need to use the `loading='lazy'` attribute on `<img>` tags.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": '',
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Largest contentful paint image was lazily loaded": {
        "page_name": "Additional items",
        "Modules": "HTML, CSS, JS",
        "task": "Additional items",
        "improvement_item": "We can look on below items if still need LCP score as better after implemented the above steps. \n * Largest contentful paint image was lazily loaded.\n * Minimise main-thread work.",
        "description": "We need to remove the `loading='lazy' attribute from large images.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Minify JavaScript": {
        "page_name": "Page speed items",
        "Modules": "CSS, JS",
        "task": "Minify CSS and JS Files",
        "improvement_item": "Minify CSS and JS Files",
        "description": "We need to remove unnecessary CSS and JavaScripts from the page to improve performance.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Reduce JavaScript execution time": {
        "page_name": "Page speed items",
        "Modules": "HTML, CSS, JS",
        "task": "Load the code based on page wise",
        "improvement_item": "Load the code based on page wise",
        "description": "We need to optimize our code by minimizing script size, breaking tasks into smaller functions, and removing unnecessary code.",
        "database": '',
        "project": '',
        "design": '',
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Minify CSS": {
        "page_name": "Page speed items",
        "Modules": "CSS, JS",
        "task": "Minify CSS and JS Files",
        "improvement_item": "Minify CSS and JS Files",
        "description": "We need to remove unnecessary CSS and JavaScripts from the page to improve performance.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Largest contentful paint element": {
        "page_name": "Page speed items",
        "Modules": "",
        "task": "Use Responsive images(Srcset)",
        "improvement_item": "Use Responsive images(Srcset)",
        "description": "We need to add `srcset` to render the images properly across different screen sizes and resolutions.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Avoid large layout shifts": {
        "page_name": "Page speed items",
        "Modules": "",
        "task": "Image or content jerk",
        "improvement_item": "Image or content jerk",
        "description": "We need to reserve space for dynamic content by setting explicit width and height for images and other media, and use CSS to manage the layout.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": '',
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Reduce unused JavaScript": {
        "page_name": "Page speed items",
        "Modules": "HTML, CSS, JS",
        "task": "Remove Unused or Unnecessary HTML, CSS and JavaScript.",
        "improvement_item": "Remove Unused or Unnecessary HTML, CSS and JavaScript.",
        "description": "We need to remove the unused HTML, CSS and JavaScripts codes.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Reduce unused CSS": {
        "page_name": "Page speed items",
        "Modules": "HTML, CSS, JS",
        "task": "Remove Unused or Unnecessary HTML, CSS and JavaScript.",
        "improvement_item": "Remove Unused or Unnecessary HTML, CSS and JavaScript.",
        "description": "We need to remove the unused HTML, CSS and JavaScripts codes.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": 0.25,
        "system": '',
        "total_efforts": '',
        "remarks": ''
    },
    "Minimise main-thread work ": {
        "page_name": "Additional items",
        "Modules": "HTML, CSS, JS",
        "task": "Additional items",
        "improvement_item": "We can look on below items if still need LCP score as better after implemented the above steps. \n * Largest contentful paint image was lazily loaded.\n * Minimise main-thread work.",
        "description": "We need to remove the `loading='lazy' attribute from large images.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": '',
        "system": '',
        "total_efforts": '',
        "empty": '',
        "remarks": ''
    },
    "Serve images in next-gen formats": {
        "page_name": "Page speed items",
        "Modules": "",
        "task": "Use Next Gen Image Formats",
        "improvement_item": "Use Next Gen Image Formats",
        "description": "We need to convert and add the images in the next gen format.",
        "database": '',
        "project": '',
        "design": 0.25,
        "qa": '',
        "api": '',
        "research": '',
        "programming": '',
        "system": '',
        "total_efforts": '',
        "remarks": ''
    }
};
// Specific titles timing variations
export const Specific_title_time = {
    "Defer JavaScript / Efficient loading third party scripts": {
        less_than20_programming: 0.5,
        less_than40_programming: 1,
        greater_than40_programming: 1.5
    },
    "Use Responsive images(Srcset) if not used": {
        less_than20_programming: 0.5,
        less_than40_programming: 1,
        greater_than40_programming: 1.5,
        less_than20_ui: 0.5,
        less_than40_ui: 1,
        greater_than40_ui: 1.5
    },
    "Load the code based on page wise": {
        less_than20_programming: 1,
        less_than40_programming: 1.5,
        greater_than40_programming: 2
    }
};
//analyse improvement values
export const improvementListDetailsHeader = {
    serialNo: "S.No",
    pageName: "Page Name",
    sectionName: "Section Name/Feature",
    issueDetails: "Feature/Issue Details",
    improvementType: "Special Feature / Issue / Improvements",
    issueType: "UI/Functionality",
    issueImpact: "Issue Impact",
    comment: "Comments",
    empty: ""
}

export const improvementListHomePage = [
    {
        page_name: 'Home page',
        name: 'Announcement bar',
        identifier: ['announcement-bar', 'announcement bar', 'announcement_bar', 'utility-bar', 'utility bar', 'utility_bar', 'info-bar', 'info bar', 'info_bar'],
        improvement_description: "We can display announcement bar section in home page.",
        feature_description: "Displayed announcement bar section in home pages.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['body'],
        comment: ''
    },
    {
        page_name: 'Header',
        name: 'Logo',
        identifier: ['logo', 'header-logo', 'header_logo', 'header logo', 'header-primary__logo'],
        improvement_description: "We can display logo in header section.",
        feature_description: "Displayed logo in header section.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['header', '#HeaderWrapper', '.header-wrapper', '#shopify-section-header'],
        comment: ''
    },
    {
        page_name: 'Header',
        name: 'Menu',
        identifier: ['menu', 'site-nav', 'site_nav', 'site nav'],
        improvement_description: "We can display menu in header section.",
        feature_description: "Displayed menu in header section.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['header', '#HeaderWrapper', '.header-wrapper', '#shopify-section-header'],
        comment: ''
    },
    {
        page_name: 'Header',
        name: 'Country selector',
        identifier: ['country/region', 'currency-converter', 'pick_currency'],
        improvement_description: "We can display country selector in header section.",
        feature_description: "Displayed country selector section.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['header', '#HeaderWrapper', '.header-wrapper', '#shopify-section-header', 'footer', '.sub-footer', '#pagefooter', '.footer-section'],
        comment: ''
    },
    {
        page_name: 'Header',
        name: 'Language Selector',
        identifier: ['language-selector, language_selector, language selector'],
        improvement_description: "We can display language selector in header section.",
        feature_description: "Displayed language selector.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['header', '#HeaderWrapper', '.header-wrapper', '#shopify-section-header', 'footer', '.sub-footer', '#pagefooter', '.footer-section'],
        comment: ''
    },
    {
        page_name: 'Header',
        name: 'Login icon/text',
        identifier: ['account-icon', 'account icon', 'account_icon', 'account', 'log in', 'log-in', 'log_in', 'login icon', 'login_icon', 'login-icon'],
        improvement_description: "We can display login icon/text in header section.",
        feature_description: "Displayed login icon/text in header section.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['header', '#HeaderWrapper', '.header-wrapper', '#shopify-section-header',],
        comment: ''
    },
    {
        page_name: 'Header',
        name: 'Cart icon',
        identifier: ['icon-cart', 'cart'],
        improvement_description: "We can display cart icon in header section.",
        feature_description: "Displayed cart icon in header section.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['header', '#HeaderWrapper', '.header-wrapper', '#shopify-section-header'],
        comment: ''
    },
    {
        page_name: 'Header',
        name: 'Search bar/icon',
        identifier: ['header__icon--search', 'search'],
        improvement_description: "We can display search bar/icon in header section.",
        feature_description: "Displayed search bar/icon in header section.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['header', '#HeaderWrapper', '.header-wrapper', '#shopify-section-header'],
        comment: ''
    },
    {
        page_name: 'Footer',
        name: 'Newsletter',
        identifier: ['footer-block--newsletter', 'newsletter'],
        improvement_description: "We can display newsletter section.",
        feature_description: "Displayed newsletter section.",
        issue_type: 'UI',
        ui_hours: 2,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['footer', '.sub-footer', '#pagefooter', '.footer-section', 'body'],
        comment: ''
    },
    {
        page_name: 'Footer',
        name: 'Menu',
        identifier: ['footer-block--menu', 'footer-menu', 'menu', 'footer__linklist', 'footer-linklist', 'footer linklist', 'list-menu__item', 'footer__block--links', 'footer_navigation', 'footer navigation', 'footer-navigation', 'footer__linklist'],
        improvement_description: "We can display menu in footer section.",
        feature_description: "Displayed menu in footer section.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['footer', '.sub-footer', '#pagefooter', '.footer-section'],
        comment: ''
    },
    {
        page_name: 'Footer',
        name: 'Policy links',
        identifier: ['policies', 'terms of service', 'return policy', 'shipping policy', 'privacy policy', 'privacy', 'shipping', 'return', 'terms & conditions', 'shipping & delivery', 'returns & exchanges'],
        improvement_description: "We can display policy links in footer section.",
        feature_description: "Displayed policy links in footer section.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['footer', '.sub-footer', '#pagefooter', '.footer-section'],
        comment: ''
    },
    {
        page_name: 'Footer',
        name: 'Copyright',
        identifier: ['©','copyright__content', 'copyright', 'powered by'],
        improvement_description: "We can display copyright section in footer section if needed.",
        feature_description: "Displayed copyright in in footer section.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['footer', '.sub-footer', '#pagefooter', '.footer-section'],
        comment: ''
    },
    {
        page_name: 'Footer',
        name: 'Social media icons',
        identifier: ['list-social', 'list-social__link', 'list-social__item', 'social-media', 'social_media', 'social media', 'facebook', 'instagram', 'twitter', 'follow us'],
        improvement_description: "We can display social media icons in footer section.",
        feature_description: "Displayed social media icons in footer section.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['footer', '.sub-footer', '#pagefooter', '.footer-section'],
        comment: ''
    },
    {
        page_name: 'Footer',
        name: 'Payment method icons',
        identifier: ['footer__payment', 'payment methods', 'payment-methods', 'payment_methods', 'payment list', 'payment_list', 'payment_list', 'american express', 'apple pay', 'diners club', 'discover', 'google pay', 'maestro', 'mastercard', 'paypal', 'shop pay', 'union pay', 'visa'],
        improvement_description: "We can display payment method icons in footer section.",
        feature_description: "Displayed payment method icons in footer section.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['footer', '.sub-footer', '#pagefooter', '.footer-section'],
        comment: ''
    },
    {
        page_name: 'Home Page',
        name: 'Banner',
        identifier: ['banner', 'slideshow__slide', 'slideshow banner', 'slideshow-banner', 'slideshow_banner'],
        improvement_description: "We can display banner section in home page.",
        feature_description: "Displayed banner section in home page.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Home Page',
        name: 'Featured collection',
        identifier: ['featured_collection', 'featured-collection', 'featured-collection', 'collection__header'],
        improvement_description: "We can display featured collection section in home page.",
        feature_description: "Displayed featured collection section in home page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Home Page',
        name: 'Reviews',
        identifier: ['reviews'],
        improvement_description: "We can display reviews in home page.",
        feature_description: "Displayed reviews in home page.",
        issue_type: 'UI & Functionality',
        ui_hours: 2,
        funtionality_hours: 1,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Home Page',
        name: 'Collection list',
        identifier: ['collection_list', 'collection-list', 'collection list', 'collection-item', 'collection_item', 'collection item'],
        improvement_description: "We can display collection list section in home page.",
        feature_description: "Displayed collection list section in home page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    }
];
export const improvementListCollection = [
    {
        page_name: 'Collection/Category',
        name: 'Announcement bar',
        identifier: ['announcement-bar', 'announcement bar', 'announcement_bar', 'utility-bar', 'utility bar', 'utility_bar', 'info-bar', 'info bar', 'info_bar'],
        improvement_description: "We can display announcement bar section in this page.",
        feature_description: "Displayed announcement bar section in this page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['body'],
        comment: ''
    },
    {
        page_name: 'Collection/Category',
        name: 'Filter',
        identifier: ['facets-container', 'facets_container', 'facets container', 'facetfiltersform', 'Filters', 'facets-drawer', 'facets_drawer', 'facets drawer', 'facets', 'filter'],
        improvement_description: "We can display filter in this page.",
        feature_description: "Displayed filter in this page.",
        issue_type: 'UI & Functionality',
        ui_hours: 1,
        funtionality_hours: 4,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Collection/Category',
        name: 'Sort',
        identifier: ['sorting', 'facetsortform', 'sort-by', 'sort by', 'sort_by', 'sortby', 'Price, low to high', 'Price, high to low', 'Date, old to new', 'Date, new to old'],
        improvement_description: "We can display sort in this page.",
        feature_description: "Displayed sort in this page.",
        issue_type: 'UI & Functionality',
        ui_hours: 1,
        funtionality_hours: 3,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Collection/Category',
        name: 'Reviews',
        identifier: ['reviews', 'review'],
        improvement_description: "We can display reviews in this page.",
        feature_description: "Displayed reviews in this page.",
        issue_type: 'UI & Functionality',
        ui_hours: 0.5,
        funtionality_hours: 1,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    }
]
export const improvementListSearch = [
    {
        page_name: 'Search',
        name: 'Announcement bar',
        identifier: ['announcement-bar', 'announcement bar', 'announcement_bar', 'utility-bar', 'utility bar', 'utility_bar', 'info-bar', 'info bar', 'info_bar'],
        improvement_description: "We can display announcement bar section in search page.",
        feature_description: "Displayed announcement bar section in search page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['body'],
        comment: ''
    },
    {
        page_name: 'Search',
        name: 'Filter',
        identifier: ['facets-container', 'facets_container', 'facets container', 'facetfiltersform', 'Filters', 'facets-drawer', 'facets_drawer', 'facets drawer', 'facets', 'filter'],
        improvement_description: "We can display filter in search page.",
        feature_description: "Displayed filter in search page.",
        issue_type: 'UI & Functionality',
        ui_hours: 1,
        funtionality_hours: 4,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Search',
        name: 'Sort',
        identifier: ['sorting', 'facetsortform', 'sort-by', 'sort by', 'sort_by', 'sortby', 'Price, low to high', 'Price, high to low', 'Date, old to new', 'Date, new to old'],
        improvement_description: "We can display sort in search page.",
        feature_description: "Displayed sort in search page.",
        issue_type: 'UI & Functionality',
        ui_hours: 1,
        funtionality_hours: 3,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Search',
        name: 'Reviews',
        identifier: ['reviews', 'review'],
        improvement_description: "We can display reviews in search page.",
        feature_description: "Displayed reviews in search page.",
        issue_type: 'UI & Functionality',
        ui_hours: 0.5,
        funtionality_hours: 1,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Search',
        name: 'Search bar with searched term',
        identifier: ['template-search__search', 'search__input-wrap', 'search__input', 'predictive__input', 'search-bar'],
        improvement_description: "We can display search bar with searched term in search page.",
        feature_description: "Displayed search bar with searched term in search page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    }
]
export const improvementListProduct = [
    {
        page_name: 'Product details page',
        name: 'Announcement bar',
        identifier: ['announcement-bar', 'announcement bar', 'announcement_bar', 'utility-bar', 'utility bar', 'utility_bar', 'info-bar', 'info bar', 'info_bar'],
        improvement_description: "We can display announcement bar section in product detail page.",
        feature_description: "Displayed announcement bar section in product detail page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['body'],
        comment: ''
    },
    {
        page_name: 'Product details page',
        name: 'Description',
        identifier: ['product__description', "product description", "product-description", "product_description", "description"],
        improvement_description: "We can display description in product details page.",
        feature_description: "Displayed description in product details page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Product details page',
        name: 'Color swatches for color variant',
        identifier: ['color swatch', 'color-swatch', 'color_swatch', 'swatch-custom-image', 'swatch-item__color'],
        improvement_description: "We can display color swatches for color variant in product details page.",
        feature_description: "Displayed color swatches for color variant in product details page.",
        issue_type: 'UI',
        ui_hours: 2,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: 'FYI:The provided hours is only for updating the color swatch for 25 products approximately. Hours will vary based on the product count and color adaption.'
    },
    {
        page_name: 'Product details page',
        name: 'Reviews',
        identifier: ['reviews'],
        improvement_description: "We can display reviews section in product details page.",
        feature_description: "Displayed reviews section in product details page.",
        issue_type: 'UI & Functionality',
        ui_hours: 4,
        funtionality_hours: 1,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Product details page',
        name: 'FAQ',
        identifier: ['frequently asked questions', 'frequently_asked_questions', 'frequently-asked-questions', 'faq'],
        improvement_description: "We can display FAQ section in product details page.",
        feature_description: "Displayed FAQ section in product details page.",
        issue_type: 'UI',
        ui_hours: 1,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    }
]
export const ImprovementProductListSection = [
    {
        page_name: 'Product details page',
        name: 'You may also like/Related products',
        identifier: ['you may also like', 'you_may_also_like', 'you-may-also-like', 'related-products', 'related_products', 'related products'],
        improvement_description: "We can display you may also like section in product details page.",
        feature_description: "Displayed you may also like section in product details page.",
        issue_type: 'UI',
        ui_hours: 0.5,
        funtionality_hours: '',
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Product details page',
        name: 'Recently viewed',
        identifier: ['recently viewed', 'recently-viewed', 'recently_viewed'],
        improvement_description: "We can display recently viewed products section in product details page.",
        feature_description: "Displayed recently viewed products section in product details page.",
        issue_type: 'UI & Functionality',
        ui_hours: 2,
        funtionality_hours: 3,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Product details page',
        name: 'Featured collection/Product recommendation',
        identifier: ['featured_collection', 'featured-collection', 'featured-collection', 'product-recommendation'],
        improvement_description: "We can display featured collection/product recommendation section in product details page.",
        feature_description: "Displayed featured collection/product recommendation section in product details page.",
        issue_type: 'UI & Functionality',
        ui_hours: 2,
        funtionality_hours: 1,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    },
    {
        page_name: 'Product details page',
        name: 'Some suggestions for you',
        identifier: ['some suggestions for you'],
        improvement_description: "We can display some suggestions for you section in product details page.",
        feature_description: "Displayed some suggestions for you section in product details page.",
        issue_type: 'UI & Functionality',
        ui_hours: 2,
        funtionality_hours: 1,
        issue_impact: '-',
        element_name: ['main', 'body'],
        comment: ''
    }
]

export const themeDetails = {
    page_name: 'Common',
    name: 'Theme',
    name_identifier: "themeName",
    version_identifier: "themeVersion",
    description: "-",
    issue: '-',
    issue_type: '-',
    ui_hours: '',
    funtionality_hours: '-',
    issue_impact: '-',
    comment: ''
}

export const defaultImprovementValidationValue = {
    term_improvement: "Improvement",
    term_feature: "Feature",
    increement: 1,
    loopStartIndex: 0,
    improvement_description: "We can display announcement bar section in all pages.",
    feature_description: "Displayed announcement bar section in all pages.",
}