import { improvementListHomePage, improvementListCollection, improvementListSearch, improvementListProduct, defaultImprovementValidationValue, themeDetails, page_dropdown, ImprovementProductListSection } from "../service";
function getDetaisByScriptAndIdentifier(scriptElement, identifier) {
  const scriptContent = scriptElement.textContent;
  const indexOfThemeName = scriptContent.indexOf(identifier);
  let value = "error";
  if (indexOfThemeName !== -1) {
    const themeNameSubstring = scriptContent.substring(indexOfThemeName);
    const equalsIndex = themeNameSubstring.indexOf("=") + 1;
    let valueEndIndex = themeNameSubstring.indexOf(";", equalsIndex);
    if (valueEndIndex === -1) {
      valueEndIndex = themeNameSubstring.indexOf("\n", equalsIndex);
    }
    value = themeNameSubstring.substring(equalsIndex, valueEndIndex).trim().replace(/['"]/g, '');
  }
  return value;
}
const getImprovementData = (pageName) => {
  if (pageName === page_dropdown.home) return improvementListHomePage;
  if (pageName === page_dropdown.collection1 || pageName === page_dropdown.collection2) return improvementListCollection;
  if (pageName === page_dropdown.slp1 || pageName === page_dropdown.slp2) return improvementListSearch;
  if (pageName === page_dropdown.pdp1 || pageName === page_dropdown.pdp2) return improvementListProduct;
  return [];
}
const extractThemeDetails = (parsedResult) => {
  const scriptElement = parsedResult.querySelector('script.boomerang');
  if (!scriptElement) return "-";
  const themeName = getDetaisByScriptAndIdentifier(scriptElement, themeDetails.name_identifier);
  const themeVersion = getDetaisByScriptAndIdentifier(scriptElement, themeDetails.version_identifier);
  let description = "";
  if (themeName.includes("error") && !themeVersion.includes("error")) {
    description = themeDetails.description;
  } else {
    if (!themeName.includes("error")) {
      description += `Theme Name: ${themeName} \n `;
    }
    if (!themeVersion.includes("error")) {
      description += `Theme Version: ${themeVersion}`;
    }
  }
  return description;
}

const addProductListingImprovement = (result, parsedResponse, productListSectionImprovementCount) => {
  ImprovementProductListSection.forEach(section => {
    let isAdded = false;
    const lastElement = section.element_name[section.element_name.length - 1];
    for (const element_name of section.element_name) {
      const targetElements = parsedResponse.querySelectorAll(element_name);
      const values = processPDPTargetElements(targetElements, section, result, productListSectionImprovementCount);
      productListSectionImprovementCount = values.productListSectionImprovementCount;
      isAdded = values.isAdded;
      if (isAdded) {
        break;
      }
      if (element_name === lastElement && !isAdded && targetElements.length === 0 && productListSectionImprovementCount < 2) {
        addImprovementResult(section, result, defaultImprovementValidationValue.term_improvement);
        productListSectionImprovementCount += defaultImprovementValidationValue.increement;
        isAdded = true;
      }
    }
  });
  return productListSectionImprovementCount;
};

const processPDPTargetElements = (targetElements, section, result, productListSectionImprovementCount) => {
  let isAdded = false;
    for (const targetElement of targetElements) {
    const targetElementSource = targetElement?.innerHTML.toLowerCase() || '';
    const hasIdentifier = section.identifier.some(identifier => targetElementSource.includes(identifier));
    const sectionResult = hasIdentifier ? defaultImprovementValidationValue.term_feature : defaultImprovementValidationValue.term_improvement;
    const description = hasIdentifier ? section.feature_description : section.improvement_description;
    if (hasIdentifier || productListSectionImprovementCount < 2) {
      addImprovementResult(section, result, sectionResult, description);
      isAdded = true;
      if (!hasIdentifier) {
        productListSectionImprovementCount += defaultImprovementValidationValue.increement;
      }
      break;
    }

  }
  return {isAdded, productListSectionImprovementCount};
};

const processSection = (section, parsedResult, result) => {
  const lastElement = section.element_name[section.element_name.length - 1];
  let isAdded = false;
  for (const element_name of section.element_name) {
    const targetElements = parsedResult.querySelectorAll(element_name);
    isAdded = processTargetElements(targetElements, section, lastElement, result, element_name);
    if (isAdded) {
      break;
    }
    if (element_name === lastElement && targetElements.length === 0 && !isAdded) {
      addImprovementResult(section, result, defaultImprovementValidationValue.term_improvement);
    }
  }
};

const processTargetElements = (targetElements, section, lastElement, result, element_name) => {
  let loopCount = 1;
  for (const targetElement of targetElements) {
    const targetElementSource = targetElement?.innerHTML.toLowerCase() || '';
    const hasIdentifier = section.identifier.some(identifier => targetElementSource.includes(identifier));
    if (hasIdentifier || (lastElement === element_name && loopCount === targetElements.length)) {     
      const sectionResult = hasIdentifier ? defaultImprovementValidationValue.term_feature : defaultImprovementValidationValue.term_improvement;
      const description = hasIdentifier ? section.feature_description : section.improvement_description;
      addImprovementResult(section, result, sectionResult, description);
      return true;
    }
    loopCount++;
  }
  return false;
};

const addImprovementResult = (section, result, sectionResult, details = section.improvement_description) => {
  result.push({
    serialNo: 0,
    pageName: section.page_name,
    sectionName: section.name,
    details,
    emptyField1: "", emptyField2: "", emptyField3: "", emptyField4: "", emptyField5: "", emptyField6: "",
    sectionResult,
    issueType: section.issue_type,
    issueImpact: section.issue_impact,
    comments: section.comment,
    emptyField7: "",
    ui_hours: section.ui_hours,
    funtionality_hours: section.funtionality_hours
  });
};

const addThemeData = (result, themeDetails, parsedResult) => {
  const themeData = extractThemeDetails(parsedResult);
  result.push({
    serialNo: 0,
    pageName: themeDetails.page_name,
    sectionName: themeDetails.name,
    details: themeData,
    emptyField1: "", emptyField2: "", emptyField3: "", emptyField4: "", emptyField5: "", emptyField6: "",
    sectionResult: themeDetails.issue,
    issueType: themeDetails.issue_type,
    issueImpact: themeDetails.issue_impact,
    comments: themeDetails.comment,
    emptyField7: "",
    ui_hours: themeDetails.ui_hours,
    funtionality_hours: themeDetails.funtionality_hours,
  });
};

const buildImprovementResult = (themeDetails, improvementData, parsedResult, pageName, productListSectionImprovementCount) => {
  const result = [];
  let finalProductListSectionImprovementCount;
  addThemeData(result, themeDetails, parsedResult);
  improvementData.forEach(section => {
    processSection(section, parsedResult, result);
  });
  if ([page_dropdown.pdp1, page_dropdown.pdp2].includes(pageName)) {
    finalProductListSectionImprovementCount = addProductListingImprovement(result, parsedResult, productListSectionImprovementCount);
  }
  return {result, finalProductListSectionImprovementCount};
};

export const analyzeImprovement = async (url, pageName, productListSectionImprovementCount = 0) => {
  const improvementData = getImprovementData(pageName);
  if (improvementData.length > 0) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ call: "get_site_content", url }),
      redirect: "follow"
    };

    try {
      const response = await fetch("https://shopifydev.vinsinfo.in/ShopifyAuditTool/UI_improvement_functions.php", requestOptions);
      const result = await response.json();
      const parsedResult = new DOMParser().parseFromString(result, 'text/html');
      const improvementResult = buildImprovementResult(themeDetails, improvementData, parsedResult, pageName, productListSectionImprovementCount);
      return improvementResult;
    } catch (error) {
      console.error(error);
      return [];
    }
  } else {
    return [
      {
        serialNo: "",
        pageName: "",
        sectionName: "",
        details: "",
        emptyField1: "", emptyField2: "", emptyField3: "", emptyField4: "", emptyField5: "", emptyField6: "",
        sectionResult: "",
        issueType: "",
        issueImpact: "",
        comments: "",
        emptyField7: "",
        ui_hours: "",
        funtionality_hours: ""
      }
    ]
  }
}

export const analyseImprovementItemBreakdown = async (improvementItems) => {
  const improvementItem = improvementItems.filter(item => item.sectionResult === "Improvement");
  const improvementItemBreakdown = []
  improvementItem.forEach(value => {
    const breakdown = {
      "S.No": improvementItemBreakdown.length + 1,
      "Task Name": value.pageName + " - " + value.sectionName,
      "Database Administration": '',
      "Project Management": '',
      "Visual Design": value.ui_hours,
      "QA": '',
      "API": '',
      "Research / Troubleshooting": '',
      "Programming": value.funtionality_hours,
      "System Administration": '',
      "UI/Functionality": value.issueType,
      "Issue/Improvements": value.sectionResult,
      "Total Efforts": '',
      "Remarks": ''
    }
    improvementItemBreakdown.push(breakdown);
  });
  return improvementItemBreakdown;
}

const updatedBreakdownItemType = (previousValue, currentValue) => {
  let current, previous;
  if (typeof previousValue === "string" && typeof currentValue === "number") {
    current=currentValue;
    previous=0;
  } else if (typeof previousValue === "number" && typeof currentValue === "string") {
    current=0;
    previous=previousValue;
  } else {
    current=currentValue;
    previous=previousValue;
  }
  return {previous, current};
}

export const analysePageWiseImprovementItemBreakdown = async (improvementItemBreakdown) => {
  let pageWiseImprovementItemBreakdown = [];
  improvementItemBreakdown.forEach(breakdown => {
    let pageName = breakdown["Task Name"].split(" - ")[0];
    if (pageWiseImprovementItemBreakdown.some(item => item["Task Name"] === pageName)) {
      for (const item of pageWiseImprovementItemBreakdown) {
        if (item["Task Name"] === pageName) {
          let designTypeUpdate = (updatedBreakdownItemType(item["Visual Design"],breakdown["Visual Design"]))
          let programmingTypeUpdate = (updatedBreakdownItemType(item["Programming"],breakdown["Programming"]));
          item["Visual Design"] = designTypeUpdate.previous+designTypeUpdate.current;
          item["Programming"] = programmingTypeUpdate.previous+programmingTypeUpdate.current;
          break;
        }
      }
    } else {
      let updatedBreakdown = {
        ...breakdown,
        "S.No": pageWiseImprovementItemBreakdown.length + 1,
        "Task Name": pageName,
      };
      pageWiseImprovementItemBreakdown.push(updatedBreakdown);
    }
  });

  return pageWiseImprovementItemBreakdown;
}